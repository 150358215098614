<script>
import Icons from '../../mixins/Icons'

export default {
  name: 'IconInstagram',
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len */ -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="w"
    :height="h"
    viewBox="0 0 200 200"
    role="presentation"
  >
    <g id="instagram">
      <g>
        <path
          :fill="color"
          d="M100,25c24.4,0,27.3,0.1,37,0.5c8.9,0.4,13.8,1.9,17,3.1c4.3,1.7,7.3,3.6,10.5,6.8
c3.2,3.2,5.2,6.2,6.8,10.5c1.3,3.2,2.7,8.1,3.1,17c0.4,9.6,0.5,12.5,0.5,37s-0.1,27.3-0.5,37c-0.4,8.9-1.9,13.8-3.1,17
c-1.7,4.3-3.6,7.3-6.8,10.5c-3.2,3.2-6.2,5.2-10.5,6.8c-3.2,1.3-8.1,2.7-17,3.1c-9.6,0.4-12.5,0.5-37,0.5s-27.3-0.1-37-0.5
c-8.9-0.4-13.8-1.9-17-3.1c-4.3-1.7-7.3-3.6-10.5-6.8c-3.2-3.2-5.2-6.2-6.8-10.5c-1.3-3.2-2.7-8.1-3.1-17
c-0.4-9.6-0.5-12.5-0.5-37s0.1-27.3,0.5-37c0.4-8.9,1.9-13.8,3.1-17c1.7-4.3,3.6-7.3,6.8-10.5c3.2-3.2,6.2-5.2,10.5-6.8
c3.2-1.3,8.1-2.7,17-3.1C72.7,25.1,75.6,25,100,25 M100,8.5c-24.8,0-28,0.1-37.7,0.6c-9.7,0.4-16.4,2-22.2,4.3
c-6,2.3-11.1,5.5-16.2,10.5c-5.1,5.1-8.2,10.2-10.5,16.2c-2.3,5.8-3.8,12.5-4.3,22.2C8.6,72,8.5,75.2,8.5,100
c0,24.8,0.1,28,0.6,37.7c0.4,9.7,2,16.4,4.3,22.2c2.3,6,5.5,11.1,10.5,16.2c5.1,5.1,10.2,8.2,16.2,10.5c5.8,2.3,12.5,3.8,22.2,4.3
c9.8,0.4,12.9,0.6,37.7,0.6s28-0.1,37.7-0.6c9.7-0.4,16.4-2,22.2-4.3c6-2.3,11.1-5.5,16.2-10.5c5.1-5.1,8.2-10.2,10.5-16.2
c2.3-5.8,3.8-12.5,4.3-22.2c0.4-9.8,0.6-12.9,0.6-37.7s-0.1-28-0.6-37.7c-0.4-9.7-2-16.4-4.3-22.2c-2.3-6-5.5-11.1-10.5-16.2
c-5.1-5.1-10.2-8.2-16.2-10.5c-5.8-2.3-12.5-3.8-22.2-4.3C128,8.6,124.8,8.5,100,8.5L100,8.5z"
        />
        <path
          :fill="color"
          d="M100,53c-25.9,0-47,21-47,47s21,47,47,47s47-21,47-47S125.9,53,100,53z M100,130.5
c-16.8,0-30.5-13.6-30.5-30.5c0-16.8,13.6-30.5,30.5-30.5c16.8,0,30.5,13.6,30.5,30.5C130.5,116.8,116.8,130.5,100,130.5z"
        />
        <circle :fill="color" cx="148.8" cy="51.2" r="11" />
      </g>
    </g>
  </svg>
</template>
